<template>
    <div>
        <v-form ref="form" v-model="valid" class="mt-4">
            <v-alert class="mt-5" v-if="switchWarning && componentSpecificProps.isTrial" type="warning" text>{{ switchWarning }}</v-alert>
            <v-text-field
                outlined
                dense
                autofocus
                class="first-name-field"
                v-model="firstName"
                :counter="50"
                label="First Name"
                :rules="[rules.nonEmpty]"
                required></v-text-field>
            <v-text-field
                outlined
                dense
                class="last-name-field"
                v-model="lastName"
                :counter="50"
                label="Last Name"
                :rules="[rules.nonEmpty]"
                required></v-text-field>
            <v-text-field
                outlined
                dense
                class="password-first-field"
                v-model="password"
                :counter="50"
                label="Password"
                required
                type="password"
                :rules="
                    password.length
                        ? [
                              rules.passwordHasNoFirstSpecialCharacter,
                              rules.passwordAllowedCharacters,
                              rules.passwordLength,
                              rules.passwordHasUppercase,
                              rules.passwordHasLowercase,
                              rules.passwordHasNumber,
                              rules.passwordHasSpecialCharacter
                          ]
                        : [rules.nonEmpty]
                "></v-text-field>
            <v-text-field
                outlined
                dense
                class="password-second-field"
                v-model="password2"
                :counter="50"
                label="Verify Password"
                required
                type="password"
                :rules="[rules.passwordMatch, rules.passwordLength]"></v-text-field>
            <v-text-field outlined dense v-model="email" label="E-mail" required readonly></v-text-field>

            <v-btn block elevation="0" color="success" :disabled="!valid || creatingAccount" :loading="creatingAccount" @click="createAccount">
                create account
            </v-btn>

            <TheInvitationModal message="Creating account" :show="creatingAccount" />
        </v-form>
    </div>
</template>

<script>
import { defaultAccountType } from '@/utils'
import store from 'store'
const TheInvitationModal = () => import('../components/TheInvitationModal')

export default {
    name: 'TheEmailSignup',
    components: { TheInvitationModal },
    props: {
        componentSpecificProps: {
            type: Object,
            default: function () {
                return {
                    userEmail: ''
                }
            }
        },
        recommended: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            valid: false,
            email: '',
            password: '',
            password2: '',
            firstName: '',
            lastName: '',
            creatingAccount: false,
            rules: {
                passwordMatch: p => this.password === p || 'Passwords do not match.',
                passwordAllowedCharacters: p =>
                    p.search(/[^a-zA-Z0-9!#$%^&().\-=_+:]/) <= -1 ||
                    'Password contains a not-allowed character. Allowed characters: a-z A-Z 0-9 ! # $ % ^ & ( ) . - = _ + :',
                passwordHasUppercase: p => p.search(/[A-Z]/) > -1 || 'Password must contain at least one uppercase letter.',
                passwordHasLowercase: p => p.search(/[a-z]/) > -1 || 'Password must contain at least one lowercase letter.',
                passwordHasNumber: p => p.search(/[0-9]/) > -1 || 'Password must contain at least one number.',
                passwordHasSpecialCharacter: p => p.search(/[!#$%^&().\-=_+:]/) > -1 || 'Password must contain at least one special character.',
                passwordLength: p => p.length >= 9 || 'Password needs to be at least 9 characters.',
                passwordHasNoFirstSpecialCharacter: p => p[0].search(/[!#$%^&().\-=_+:]/) === -1 || 'Password cannot start with special character.',
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            }
        }
    },
    computed: {
        switchWarning() {
            if (defaultAccountType(this.email) === 'SWITCH') {
                return 'Based on your email address, please consider signing up with your SWITCH account using the above option.'
            }
            return ''
        }
    },
    methods: {
        createAccount() {
            this.creatingAccount = true
            store.set('nvLastEmail', this.email)
            this.$axios
                .post(
                    `/users/register_with_invitation/auth0/${this.$route.params.invid}`,
                    {
                        email: this.email,
                        family_name: this.lastName,
                        given_name: this.firstName,
                        password: this.password
                    },
                    { timeout: 300000 }
                )
                .then(() => {
                    this.creatingAccount = false
                    this.$nextTick(() => {
                        this.$auth.loginWithRedirect({
                            connection: 'DataHub-Test',
                            login_hint: this.email,
                            appState: {
                                targetUrl: this.$router.resolve({
                                    name: 'invitation-accept',
                                    params: { invid: this.$route.params.invid }
                                }).href
                            }
                        })
                    })
                })
                .catch(error => {
                    this.creatingAccount = false
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Account creation failed',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                    if (error.response && error.response.data && error.response.data.code === 'password_complexity_error') {
                        this.$store.dispatch('showGlobalDialog', {
                            dialogTitle: 'Password complexity error',
                            dialogText: 'Your password does not satisfy the password complexity constraints. Note that your password cannot contain your name.',
                            dialogAction: ['dismiss']
                        })
                    }
                })
        }
    },
    mounted() {
        this.email = this.$props.componentSpecificProps.userEmail
    }
}
</script>
